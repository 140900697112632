import React from 'react';
import PropTypes from 'prop-types';

import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';

const Collaboration = ({ image, name, link }) => {
  const img = image && image.asset && (
    <img className="object-cover " 
        src={imageUrlFor(buildImageObj(image))
            .height(200)
            .auto('format')
            .url()}
      alt={image.alt} />
  );
  const anchor = link 
    ? <a href={link} target="_blank" rel="noreferrer" className="flex flex-col items-center" >{img}</a>
    : img;
  return (
    <div className="w-full md:w-5/12 flex flex-col justify-center items-center m-6 pt-8 transition duration-700 p-4 hover:opacity-75 hover:shadow-lg">
      {anchor}
      { !link && <p className="font-bold">{name}</p> }
    </div>
  )
};

Collaboration.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  link: PropTypes.string
};

export default Collaboration;