import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Collaboration from "../components/collaboration";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql`
  query SamenwerkingQuery {
    sanityCollaborationCollection(_id: { eq: "collaborationCollection" }) {
      collaborations {
        image {
          ...SanityImage
        }
        name
        link
        _key
      }
      heading
    }
  }
`;
const Partners = ({ data }) => {
  const collaborations = data.sanityCollaborationCollection.collaborations;
  return (
    <Layout>
      <SEO title={data.sanityCollaborationCollection.heading} />
      <div className="px-8 max-w-screen-xl p-4 mx-auto md:px-8 ">
        <h1 className="uppercase font-bold text-2xl sm:text-4xl text-blue-branding text-center">
          {data.sanityCollaborationCollection.heading}
        </h1>
        <section className="flex flex-col md:flex-row sm:flex-wrap justify-around items-center">
          {collaborations.map((c) => (
            <Collaboration key={c._key} {...c} />
          ))}
        </section>
      </div>
    </Layout>
  );
};

Partners.propTypes = {
  data: PropTypes.object,
};

export default Partners;
